import * as React from 'react';
import { useState, useEffect } from 'react';
import { bdAxios } from '../components/utils/Axios';
import { parseJwt, getToken } from '../components/utils/Common';

function AdminRatio()
{

	const [message, setMessage] = useState<string>();
	const [yearsAccess, setYearsAccess] = useState<Array<any>>([]);

	// const yearStart: number = 2019;
	// const yearEnd: number = (new Date().getFullYear())+3;
	// const yearDiff = yearEnd - yearStart;
	// const years: any= Array.from({ length: yearDiff }, (_, i) => yearStart+i);

	useEffect(() => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}
		bdAxios.get('ratios/configs', {headers:{
			'Authorization': "Bearer " + token
		}}).then((r:any) => {
			let acc: any = r.data.configs.ratios;
			let lastAccessYear: any = 0;
			acc.map((yearAccess: any) => {
				if (parseInt(yearAccess.year) > lastAccessYear) {
					lastAccessYear = parseInt(yearAccess.year);
				}
			});

			// const maxYear = new Date().getFullYear() + 3)
			const accYears: any= Array.from({ length: 3 }, (_, i) => (parseInt(lastAccessYear)+i+1));
			accYears.map((y: any) => {
				acc.push({
					"year": y,
					"opened": false,
					"end_date": null,
					"start_date": null,
					"acces_cumul": false,
					"retainer": 0,
				})
			})
			setYearsAccess(acc);

		});
	}, []);
	const submit = () => {
		let token = (getToken() ? getToken() : '');
		if (token === null) {
			return;
		}

		const form : any = document.querySelector("#ratio-configs");
		const formData : FormData = new FormData(form);
		bdAxios.post('update/configs', formData, {
			headers: {
				'Authorization': "Bearer " + token
			}
		}).then((r) => {

			setMessage('Configurations mises à jour avec succès.');
		})
		setTimeout(() => {
			setMessage('');
		}, 3000)
	}
	return (
		<div className="ratio-admin-wrpr">
			<h2>Administration</h2>
		
			<form id="ratio-configs">

				{
					message && message !== '' ?
						<div className="transition-opacity p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
						  <span className="font-medium">{message}</span>
						</div>
					: void(0)
				}
					
				
						<fieldset>
							<table>
								<thead>
									<tr>
										<th>Année</th>
										<th>Accès au formulaire</th>
										<th>Date de début</th>
										<th>Date de fin</th>
										<th>Prélèvement</th>
										<th>Accès au cumulatif d’ensemble</th>
									</tr>
								</thead>
								<tbody>
									{
										yearsAccess && yearsAccess.length > 0 ?
											yearsAccess.map((year:any) => {
												return (
													<tr key={year.year}>
														<td>{year.year}</td>
														<td>
															<input
																type="checkbox"
																defaultChecked={year.opened}
																value="1"
																name={'config['+year.year+'][state]'}
															/>
														</td>
														<td>
															<input type="date"
																className="small"
																defaultValue={year.start_date}
																name={'config['+year.year+'][start]'}
															/>
														</td>
														<td>
															<input type="date"
																className="small"
																defaultValue={year.end_date}
																name={'config['+year.year+'][end]'}
															/>
														</td>
														<td>
															<input
																type="checkbox"
																defaultChecked={year?.acces_cumul}
																value="1"
																name={'config['+year.year+'][acces_cumul]'}
															/>
														</td>
														<td>
															<input name={'config['+year.year+'][retainer]'} type="number" step="0.01" defaultValue={year.retainer} />
														</td>
													</tr>
												)
											})
										: void(0)
									}
								</tbody>
							</table>
						</fieldset>
			</form>
			<div className="btnrow">
				<div onClick={submit} className="button">Enregistrer</div>
			</div>
		</div>
	)
}

export default AdminRatio;